<script>
import EditPayeeMdl from './edit-payee.mdl.vue'

export default {
  name: 'edit-payee-bottom-sheet',
  extends: EditPayeeMdl,
}
</script>

<template>
  <validation-observer
    v-slot="{ invalid, handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <div>
      <div
        class="relative flex h-[72px] w-full items-center justify-center border-b text-center text-xl font-bold"
      >
        <div v-if="isSymbionPayee">Edit account name</div>
        <div v-else>Edit payee name</div>
        <div class="absolute right-5 top-6">
          <base-button
            icon="heroicons/outline/x-mark"
            :size="32"
            @click="onCancel()"
          />
        </div>
      </div>
      <div class="px-5">
        <div v-if="isSymbionPayee" class="mt-[30px] text-eonx-neutral-600">
          Set a custom account name to easily differentiate between your
          statements. This is set to account number by default.
        </div>

        <div class="my-[30px] w-full">
          <template v-if="isSymbionPayee">
            <base-input
              v-model="form.name"
              :validation="{
                rules: 'required|max:256',
                name: 'Account name',
              }"
              :error="backendErrors.name"
              :disabled="processing"
              label="Account name"
              required
              name="name"
              maxlength="256"
            />
          </template>

          <template v-else-if="isBpayPayee">
            <base-input
              v-model="form.name"
              :validation="{
                rules: 'required|max:256',
                name: 'Biller name',
              }"
              :error="backendErrors.name"
              :disabled="processing"
              label="Biller name"
              required
              name="name"
              maxlength="256"
            />

            <base-input
              :value="payee.reference"
              label="Reference no."
              disabled
              required
            />

            <base-input
              :value="payee.billerCode"
              label="Biller code"
              disabled
              required
            />
          </template>

          <template v-else-if="isBankAccountPayee">
            <base-metafield
              v-model="form"
              :submitting="processing"
              :schema="bankAccountSchemaEditName"
              :backend-errors="backendErrors"
            />
          </template>
        </div>
      </div>

      <div
        class="flex w-full items-center justify-center border-t px-[30px] py-5"
      >
        <base-button
          class="mx-2.5 w-1/2"
          look="outlined-color"
          @click="onCancel"
        >
          Cancel
        </base-button>
        <base-button
          class="mx-2.5 w-1/2"
          :disabled="processing || !form.name || invalid"
          :loading="processing"
          @click="handleSubmit(onUpdate)"
        >
          Save
        </base-button>
      </div>
    </div>
  </validation-observer>
</template>
